import { useState } from "react";

const categories = [
    { name: "Museums", id: "museum", },
    { name: "Waterfalls", id: "waterfalls" },
    { name: "Mountains", id: "mountains" },
    { name: "Religious Places", id: "religiousPlaces" },
    { name: "Vineyards", id: "vineyards" },
];

const cardsData = {
    museum: [
        {
            title: "Gargoti Museum",
            image: "/Card/Gargoti.jpg",
            description:
                "The Gargoti Museum, showcases the world’s largest private gem and mineral collection, including rare Indian zeolite crystals. Divided into two galleries, it’s a must-visit for anyone interested in the natural world.",
        },
        {
            title: "Artillery Museum",
            image: "/Card/Artillary.png",
            description:
                "Artillery museums offer a deep dive into the world of artillery, featuring historical cannons, modern howitzers, and more. They showcase real or replica equipment, photographs, and uniforms, with some even hosting live gun-firing demonstrations.",
        },
        {
            title: "Coin Museum",
            image: "/Card/Coin.jpg",
            description:
                "The Coin Museum in Nashik, set against Ajneri Hill, is Asia’s only museum dedicated to India’s numismatic history. Featuring coins, ancient minting dioramas, and historical artifacts, it’s a must-visit for history lovers.",
        },
    ],
    waterfalls: [
        {
            title: "Someshwar Waterfalls",
            image: "/Card/SomeshwarW.jpg",
            description:
                "Located just 2 kilometers from Someswar Temple, Someshwar Waterfall offers a breathtaking view, especially during the monsoon season. It’s a perfect spot to relax and unwind amidst nature's beauty.",
        },
        {
            title: "Vihigaon Waterfall",
            image: "/Card/VihigaonW.jpg",
            description:
                "Vihigaon Waterfall, also known as Ashoka Waterfall, features a huge flow of crystal-clear water, perfect for a refreshing dip. With its tranquil atmosphere and fewer crowds, it’s an ideal escape for nature lovers.",
        },
        {
            title: "Dabhosa Waterfall",
            image: "/Card/Dabhosa.jpg",
            description:
                "Nestled in the village of Dabhosa, this stunning waterfall is one of the tallest in the region and creates a breathtaking sight, especially during the monsoon. It’s a scenic getaway worth visiting.",
        },
    ],
    mountains: [
        {
            title: "Brahmagiri Hill",
            image: "/Card/Brahmagiri.jpg",
            description:
                "Brahmagiri Hill, rising to 1,298 meters, is a sacred site in the Western Ghats and the source of the Godavari River. Rich in mythological significance, it’s believed to be the residence of Sage Gautama and holds deep cultural importance.",
        },
        {
            title: "Harihargad Fort",
            image: "/Card/Harihargad.jpg",
            description:
                "Harihar Fort, about 40 kilometers from Nashik, is known for its unique rock-cut steps and historical significance as a trade route overseer. Visitors can explore a well-preserved storage house and rock-cut water cisterns within the fort.",
        },
        {
            title: "Pandavleni Hills",
            image: "/images/caves.png",
            description:
                "Pandavleni Hills features over 2,000 rock-cut caves dating back to the 2nd century BCE, used by Buddhist monks for dwelling and worship. This archaeological site offers intricate carvings and a glimpse into ancient Indian culture.",
        },
    ],
    religiousPlaces: [
        {
            title: "Kala-Ram Mandir",
            image: "/Card/Kala-Ram.jpg",
            description:
                "Kalaram Temple in Nashik, built in 1788, is a historic site linked to the Dalit movement and features a black statue of Lord Rama. It attracts many pilgrims, especially those visiting the nearby Kapaleshwar Mahadev Temple.",
        },
        {
            title: "Trimbakeshwar",
            image: "/Card/Trimbakeshwar.jpg",
            description:
                "Trimbakeshwar Temple is one of the twelve Jyotirlingas dedicated to Lord Shiva, located near the Godavari River's source. This sacred site is a major pilgrimage destination for Hindus and houses important genealogy registers.",
        },
        {
            title: "Someshwar Temple",
            image: "/Card/SomeshwarT.jpg",
            description:
                "Someshwar Temple, dedicated to Lord Shiva, offers a peaceful ambiance amidst lush greenery, making it a serene spot for meditation and prayer. Its beautiful architecture and tranquil surroundings attract spiritual seekers.",
        },
    ],
    vineyards: [
        {
            title: "Sula Vineyard",
            image: "/Card/SulaV.jpg",
            description:
                "Sula Vineyards, a leading winery in Nashik, is famous for its high-quality wines made from international grape varieties. Visitors can enjoy wine tastings, vineyard tours, and delicious dining experiences amid scenic surroundings.",
        },
        {
            title: "Soma Vine Village",
            image: "/Card/SomaVine.jpg",
            description:
                "Soma Vineyards offers a unique and intimate wine experience, focusing on high-quality wines from indigenous Indian grapes. Visitors can enjoy guided tours, tastings, and a peaceful ambiance amidst beautiful landscapes.",
        },
        {
            title: "York Winery & Tasting Room",
            image: "/Card/York.webp",
            description:
                "York Winery & Tasting Room is a boutique winery known for its quality and innovation, offering personalized tours and tastings. Guests can enjoy a cozy atmosphere and knowledgeable staff dedicated to sharing their passion for winemaking.",
        },
    ],
};

const TabbedCard = () => {
    const [selectedCategory, setSelectedCategory] = useState("museum");

    const handleCategoryClick = (id) => {
        setSelectedCategory(id);
    };

    const selectedCards = selectedCategory ? cardsData[selectedCategory] : [];

    return (
        <section className="p-8 pb-16 mt-16 text-center lg:text-left flex flex-col items-center">
            <h2 className="text-3xl md:text-5xl font-bold mb-4 md:mb-8">Things You Must Do</h2>

            <div className="flex flex-wrap gap-2 mb-8">
                {categories.map((category) => (
                    <button
                        key={category.id}
                        onClick={() => handleCategoryClick(category.id)}
                        className={`flex items-center justify-center px-4 py-2 rounded-full text-sm h-8 border border-gray-900 ${
                            selectedCategory === category.id
                                ? "bg-gray-800 text-white"
                                : "text-gray-700"
                        }`}
                    >
                        {category.name}
                    </button>
                ))}
            </div>

            <div className="grid gap-8 md:grid-cols-3">
                {selectedCards?.map((card, index) => (
                    <div
                        key={index}
                        className="border rounded-lg overflow-hidden shadow-md max-w-md"
                    >
                        <div className="h-80 overflow-hidden">
                            <img
                                src={card.image}
                                alt={card.title}
                                className="h-full w-full object-cover  transform transition-transform duration-500 hover:scale-125"
                            />
                        </div>
                        <div className="p-4">
                            <h3 className="font-semibold text-lg">{card.title}</h3>
                            <p className="text-gray-600 mt-2 text-base">
                                {card.description}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default TabbedCard;
