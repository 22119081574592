import React from "react";

function Hero() {
    return (
        <div
            className="relative w-100 overflow-x-hidden text-start bg-cover bg-center bg-no-repeat"
            style={{
                backgroundImage: "url('/images/sky_gif.gif')",
                // minHeight: "calc(100vw * 1.5)",
            }}
        >
            <div className="absolute top-0 left-0 w-full h-full bg-black opacity-20"></div>
            <div className="z-20 relative container overflow-x-hidden pt-20 mx-auto px-10 py-16 flex flex-row items-center justify-between min-h-screen">
                <div className="w-full overflow-x-hidden md:w-3/4  text-left">
                    <h1 className="text-5xl sm:text-6xl md:text-7xl font-bold text-white font-poppins tracking-tighter ">
                        The New Era
                        <br /> of Travel and Living <br /> is AI-Enhanced
                        <br />
                    </h1>
                    <p className="text-gray-200 mt-4 text-lg font-Inter">
                        Build stronger connections with Nashik through a
                        comprehensive information platform.
                    </p>
                    <button className="mt-8 m-1 px-4 py-2 bg-black text-white font-bold rounded active:ring-indigo-50 active:ring-2">
                        Join waitlist
                    </button>
                </div>
            </div>

            <div className="z-40 relative container mx-auto py-24 px-10 md:px-24 justify-center">
                <div className="text-center">
                    <h3 className="text-white font-sans text-2xl md:text-4xl font-medium my-12">
                        Discover नाशिक like never before with the power of AI.
                    </h3>
                    <img className="w-full" src="/images/wireframe1.png" />
                </div>
            </div>
        </div>
    );
}

export default Hero;
