import React from 'react'
import Hero from '../../components/LandingSec'
import Letter from './Letter'
import Results from '../../components/Results'
// import StickyNavbar from '../../components/Navbar'
import Footer from '../../components/Footer'
// import CompetitionCard from '../../components/CompetitionCard'
import TabbedCard from '../../components/TabbedCard'

function Home() {
  return (
    <div className='scrollbar scrollbar'>
        <Hero />
        <Letter /> 
        <TabbedCard />
        <Results />
        {/* <CompetitionCard /> */}
        <Footer />
    </div>
  )
}

export default Home