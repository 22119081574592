import React from "react";

function Results() {
    return (
        <div id="results" className="w-full text-start px-4 bg-amber-100 pb-28">
            <h3 className="max-w-screen-lg font-poppins font-bold text-3xl sm:text-4xl md:text-5xl mx-auto py-28 tracking-tight">
                The beauty of Nashik speaks for itself,
                <span className="inline md:block md:text-right ml-auto">
                    so do our visitors.
                </span>
            </h3>

            <div className="grid grid-cols-2 md:grid-cols-4 gap-[10px] w-full max-w-screen-xl m-auto">
                {/* 1 */}
                <div className="flip-card w-full h-full">
                    <div className="flip-card-inner">
                        <div className="flip-card-front bg-white flex justify-center items-center border shadow-md h-52 overflow-hidden">
                            <strong className="font-handjet font-semibold text-2xl md:text-5xl text-center">
                            Sula Vineyards
                            </strong>
                        </div>
                        <div className="flip-card-back bg-white flex justify-center items-center border shadow-md p-0">
                            <img
                                src="/images/sula.png"
                                alt="Vineyard"
                                className="max-w-full w-full h-full object-cover"
                            />
                        </div>
                    </div>
                </div>
                {/* 2 */}
                <div className="flip-card w-full h-full">
                    <div className="flip-card-inner">
                        <div className="flip-card-front bg-white flex justify-center items-center border shadow-md h-52 overflow-hidden">
                            <img
                                src="/images/trimbakeshwar.png"
                                alt="Trimbakeshwar Temple"
                                className="max-w-full w-full h-full object-cover"
                            />
                        </div>
                        <div className="flip-card-back bg-white flex justify-center items-center border shadow-md p-0">
                            <strong className="font-light text-black font-serif text-xl md:text-3xl text-center">
                                Trimbakeshwar <br /> Temple
                            </strong>
                        </div>
                    </div>
                </div>
                {/* 3 */}
                <div className="bg-sky-300 col-span-2 p-8 sm:p-8 flex justify-center items-center border shadow-md">
                    <div className="w-full h-full font-sans">
                        <p className="text-lg sm:text-xl">
                            Trimbakeshwar Temple, It is dedicated to Lord Shiva and is one of
                            the twelve{" "}
                            <span className="text-blue-900 font-bold">
                                Jyotirlingas
                            </span>
                            , considered to be the most sacred abodes of Shiva.
                        </p>
                    </div>
                </div>
                {/* 4 */}
                <div className="flip-card w-full h-full">
                    <div className="flip-card-inner">
                        <div className="flip-card-front bg-white flex justify-center items-center border shadow-md h-52 overflow-hidden">
                            <img
                                src="/images/caves.png"
                                alt="Pandavleni Caves"
                                className="max-w-full w-full h-full object-cover"
                            />
                        </div>
                        <div className="flip-card-back bg-white flex justify-center items-center border shadow-md p-0">
                            <strong className="font-light text-black font-serif text-lg md:text-2xl text-center px-4">
                                Ancient rock-cut caves believed to be associated
                                with the Pandavas of the Mahabharata.
                            </strong>
                        </div>
                    </div>
                </div>
                {/* 5 */}
                <div className="flip-card w-full h-full">
                    <div className="flip-card-inner">
                        <div className="flip-card-front bg-white flex justify-center items-center border shadow-md h-52 overflow-hidden">
                            <strong className="font-dmsans font-normal text-xl md:text-4xl text-center">
                                Ramkund
                            </strong>
                        </div>
                        <div className="flip-card-back bg-white flex justify-center items-center border shadow-md p-0">
                            <img
                                src="/images/ramkund.png"
                                alt="Ramkund"
                                className="max-w-full w-full h-full object-cover"
                            />
                        </div>
                    </div>
                </div>
                {/* 6 */}
                <div className="flip-card w-full h-full">
                    <div className="flip-card-inner">
                        <div className="flip-card-front bg-white flex justify-center items-center border shadow-md h-52 overflow-hidden">
                            <img
                                src="/images/igatpuri.png"
                                alt="Igatpuri Hills"
                                className="max-w-full w-full h-full object-cover"
                            />
                        </div>
                        <div className="flip-card-back bg-white flex justify-center items-center border shadow-md p-0">
                            <strong className="font-light text-black font-inter text-lg md:text-2xl text-center px-4">
                                Igatpuri Hills, A popular hill station with
                                scenic views and adventure activities.
                            </strong>
                        </div>
                    </div>
                </div>
                {/* 7 */}
                <div className="flip-card w-full h-full">
                    <div className="flip-card-inner">
                        <div className="flip-card-front bg-white flex justify-center items-center border shadow-md h-52 overflow-hidden">
                            <strong className="font-handjet font-light text-2xl md:text-5xl px-4 text-center">
                                Nashik City Centre
                            </strong>
                        </div>
                        <div className="flip-card-back bg-white flex justify-center items-center border shadow-md p-0">
                            <img
                                src="/images/ccm.png"
                                alt="CCM"
                                className="max-w-full w-full h-full object-cover"
                            />
                        </div>
                    </div>
                </div>
                {/* 8 */}
                <div className="bg-rose-300 col-span-2 p-8 flex justify-center items-center border shadow-md">
                    <div className="w-full h-full font-serif">
                        <p className="text-md text-lg sm:text-xl">
                            Nashik's Misal Pav, a spicy and flavorful dish made with a mixture
                            of sprouted lentils, vegetables, and tangy curry,
                            served with toasted pav bread.
                        </p>
                    </div>
                </div>
                {/* 9 */}
                <div className="flip-card w-full h-full">
                    <div className="flip-card-inner">
                        <div className="flip-card-front bg-white flex justify-center items-center border shadow-md h-52 overflow-hidden">
                            <img
                                src="/images/misal.png"
                                alt="Misal Pav"
                                className="max-w-full w-full h-full object-cover"
                            />
                        </div>
                        <div className="flip-card-back bg-white flex justify-center items-center border shadow-md p-0">
                            <strong className="font-light text-black font-inter text-xl md:text-3xl text-center">
                                Misal Pav
                            </strong>
                        </div>
                    </div>
                </div>
                {/* 10 */}
                <div className="flip-card w-full h-full">
                    <div className="flip-card-inner">
                        <div className="flip-card-front bg-white flex justify-center items-center border shadow-md h-52 overflow-hidden">
                            <strong className="font-mono font-normal text-xl md:text-4xl text-center">
                                Shiridi
                            </strong>
                        </div>
                        <div className="flip-card-back bg-white flex justify-center items-center border shadow-md p-0">
                            <img
                                src="/images/shiridi.png"
                                alt="Shiridi"
                                className="max-w-full w-full h-full object-cover"
                            />
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default Results;
