import React, { useState, useEffect, useRef } from "react";

function Letter() {
    const h2Ref = useRef(null);
    const [h2Width, setH2Width] = useState("auto");

    useEffect(() => {
        if (h2Ref.current) {
            setH2Width(`${h2Ref.current.offsetWidth}px`);
        }
    }, []);
    return (
        // <div className="py-16 md:py-36 px-8 md:px-20 text-start bg-gradient-to-r from-white to-transparent "
        //      style={{backgroundImage: "url('/images/pastel.jpg')",}}
        //      >
        <div
            className="py-16 md:py-36 px-8 md:px-20 text-start bg-white md:bg-transparent"
            style={{
                backgroundImage:
                    window.innerWidth >= 768
                        ? "linear-gradient(to right, white,white, transparent), url('/images/temple_.jpg')"
                        : "white",
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}
        >
            <div className="">
                <h2
                    ref={h2Ref}
                    className="font-bold font-poppins text-4xl inline-block"
                >
                    An open letter from our Team:
                    <br />
                    Welcome to the Age of AI-first
                </h2>
                <br />
                <br />
                <div
                    style={{ maxWidth: h2Width }}
                    className="font-inter text-xl md:text-2xl flex-shrink tracking-tight"
                >
                    <article className="space-y-4">
                        <p className="">
                        &emsp; &emsp;We're so excited to introduce you to My Nashik AI.
                            It's our little digital love letter to the city we
                            call home.
                        </p>
                        <p>
                            Remember those days when planning a
                            weekend getaway or finding a new spot to eat was a
                            bit of a treasure hunt? We do! And that's exactly
                            why we decided to create My Nashik AI. We wanted a
                            platform that would make exploring Nashik as easy
                            and fun as a stroll along the Ramkund.
                        </p>
                        <p>
                            With My Nashik AI, you'll be able to
                            discover hidden gems, plan the perfect outing, and
                            connect with other locals who share your love for
                            this amazing city. It's like having your own
                            personal Nashik guide right at your fingertips.
                        </p>
                        <p className="">
                            Let's uncover the hidden treasures,
                            taste the delicious flavors, and create memories
                            that will last a lifetime. Nashik is more than just
                            a place; it's a feeling. And My Nashik AI is here to
                            help you experience it to the fullest.
                        </p>
                    </article>
                </div>
            </div>
        </div>
    );
}

export default Letter;
